import QueryString from 'query-string';
import getConfig from 'next/config';
import updateRouterQuery from '../../lib/updateRouterQuery';
import scrollToTop from '../../lib/scrollToTop';
import routes from '../../constants/routes';
import { navigateTo, POST_TYPES } from '../../lib/helpers/navigation';
import { appendQuery } from '../../lib/location';

export const getReturnUrlWithBasketOpen = () => {
  const query = QueryString.parse(window.location.search);
  query.basketOpen = true;
  query.siteCode = '';
  const queryWithBasketOpen = QueryString.stringify(query);
  return `${window.location.href.split('?')[0]}?${queryWithBasketOpen}`;
};

export const redirectViaRouterToLoginWithBasketOpen = () => {
  const { LoginUrl } = getConfig().publicRuntimeConfig.ENVIRONMENT;

  const url = appendQuery(LoginUrl, {
    ReturnUrl: getReturnUrlWithBasketOpen(),
    UseReturnURL: 'true',
  });

  navigateTo(url, {
    type: POST_TYPES.LOGIN,
  });
};

export const hasPageErrors = () => {
  const htmlElements = Array.from(document.querySelectorAll('[data-error]'));
  if (
    htmlElements.find((ele) => ele.attributes?.['data-error']?.value === 'true')
  ) {
    return true;
  }
  return false;
};

export const addToBasketRedirect = (
  client,
  query = {},
  campsiteName,
  toggleBasket,
  pushToDataLayer,
  upsellData,
  expandedBasket,
) => {
  const { campaignCode = null, ukItinerary } = query;
  if (localStorage.pendingCampbookingAdd || !client || hasPageErrors()) {
    return;
  }
  if (pushToDataLayer) {
    pushToDataLayer();
  }

  // Update router query.
  updateRouterQuery(routes.sites, {
    componentId: null,
    campsiteId: query.bookingId ? query.campsiteId : null,
    campsiteName: null,
    location: null,
    showResults: ukItinerary ? true : null,
    siteCode: null,
    campaignCode,
  });

  // Scroll to the top of main
  scrollToTop();

  // showUpSell is dependent on
  // 1 - on a return retail crossing being in the basket,
  // 2 - a itx version being available
  // 3 - the itx is cheaper
  // 4 - the number of nights is >= 4
  if (upsellData?.showUpSell) {
    client.writeData({
      data: {
        upSellPopUp: {
          __typename: 'UpSellPopUp',
          open: true,
          saving: upsellData?.upSellSaving,
        },
      },
    });
  } else {
    // Update router query and enable add to basket confirmation modal.
    client.writeData({
      data: {
        basketPopUp: {
          __typename: 'BasketPopUp',
          open: true,
          name: `${campsiteName} site`,
        },
      },
    });

    // Expand basket
    toggleBasket(true, expandedBasket);
  }
};

export const addToBasketRedirectToCheckout = (pushToDataLayer) => {
  if (pushToDataLayer) {
    pushToDataLayer();
  }
  navigateTo(routes.checkout);
};

export const quoteHasOverlapBooking = (quote) => {
  if (!quote?.campbookingBookings?.length) {
    return false;
  }
  return quote.campbookingBookings.some((campbookingBooking) => campbookingBooking.pitches.some(
    (pitch) => !!pitch.preventBookingComponents?.length,
  ));
};

// Gets the departure dates from a booking object
//
// @param   booking                 Any booking type (Campsite or Crossing Booking)
//
// @returns date (string | null)    The departure date of the supplied booking
//
export const getTravelDatesFromBooking = (booking) => {
  // eslint-disable-next-line no-underscore-dangle
  if (booking.__typename === 'Pitch' || booking.__typename === 'BookingPitch') {
    return booking.bookingDates.fromDate;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (booking.__typename === 'CrossingBooking') {
    return booking.outboundItinerary.timeTable.fromDate;
  }

  return null;
};

// Sorts bookings by departure date
//
// @param   bookingA    Any booking type (Campsite or Crossing Booking)
// @param   bookingB    Any booking type (Campsite or Crossing Booking)
//
// @returns booking[]   The list of bookings sorted by departureDate
//
export const sortBookings = (bookingA, bookingB) => {
  const departureDateA = getTravelDatesFromBooking(bookingA);
  const departureDateB = getTravelDatesFromBooking(bookingB);
  return new Date(departureDateA) - new Date(departureDateB);
};
