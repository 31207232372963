import styled, { css } from 'styled-components';
import { ButtonBrand } from '../../Button';
import { ModalContent } from '..';
import ButtonBordered from '../../Button/ButtonBordered.style';
import { below } from '../../../../styles/libs';

export const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 0rem 1.5rem;
  margin: -1.5rem -1.5rem 1.5rem;
  height: 4.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 1.5rem 1.5rem;
  margin: 1.5rem -1.5rem -1.5rem -1.5rem;
  border-top: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  ${({ hasSecondaryAction }) => hasSecondaryAction && css`
    justify-content: space-between;
  `}

  ${({ theme }) => below(theme.MOBILE_LARGE)`
    flex-direction: column;
  `}
`;

export const ModalContentStyled = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  width: 600px;
  max-width: 90%;
  background-color: ${({ theme }) => theme.COLOR_WHITE};

  ${({ center }) => center && css`
    align-items: center;
    padding: 1.5rem 4rem;
  `}
  ${({ theme }) => below(theme.MOBILE_LARGE)`
    padding: 1.5rem 3rem;
  `}
`;

export const FooterActionCSS = css`
  min-width: 10rem;
  padding: 0.5rem;

  ${({ theme }) => below(theme.MOBILE_LARGE)`
    &:last-of-type {
      margin-top: 1rem;
    }
  `}
`;

export const FooterAction = styled(ButtonBrand)`
  ${FooterActionCSS}
`;

export const FooterActionSecondary = styled(ButtonBordered)`
  ${FooterActionCSS}
`;
