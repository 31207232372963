import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';

import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';

import Modal, { ModalContent, ModalClose } from '../ui/Modal';
import Title from '../ui/Title';
import Text from '../ui/Text';
import {
  Actions,
  Action,
} from '../PopUp/PopUp.styles';
import theme from '../../styles/config/theme';

import IbePropTypes from '../../IbePropTypes';
import BasketHoldInventoryCountDown from './BasketHoldInventoryCountDown';

const ActionsStyled = styled(Actions)`
  justify-content: center;
`;

const ActionStyled = styled(Action)`
  flex-grow: 0;
  padding: 0rem 1.5rem;
`;

function DialogHoldInventory({
  client,
  dictionaryRootName,
  onClose,
  onConfirm,
  open,
  quoteId,
  timeRemaining,
}) {
  const titleRef = useRef(getDictionaryItem(client, `${dictionaryRootName}__Title`));
  const quoteString = `${getDictionaryItem(client, `${dictionaryRootName}__BasketQuoteLabel__Text`)} #${quoteId}`;

  return (
    <Modal active={open} overlay>
      <ModalContent size="small">
        <ModalClose onClick={onClose} />

        <Title
          align="center"
          tag={3}
          size={3}
          marginBottom
          marginTop
        >
          <BasketHoldInventoryCountDown
            timeRemaining={timeRemaining}
            embedString={titleRef.current}
          />
        </Title>
        <Text
          align="center"
          dictionary={dictionaryItem(dictionaryRootName, 'Explanation')}
          marginBottom="1rem"
          size="1rem"
        />
        <Text
          align="center"
          size="0.75rem"
          color={theme.COLOR_LAKE_GREEN_DARK}
        >
          {quoteString}
        </Text>

        <ActionsStyled>
          <ActionStyled
            size="small"
            dictionary={dictionaryItem(dictionaryRootName, 'Close')}
            onClick={onConfirm}
          />
        </ActionsStyled>

      </ModalContent>
    </Modal>
  );
}

DialogHoldInventory.propTypes = {
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  dictionaryRootName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  quoteId: PropTypes.string.isRequired,
  timeRemaining: PropTypes.number,
};

DialogHoldInventory.defaultProps = {
  open: false,
  timeRemaining: undefined,
};

export default withApollo(DialogHoldInventory);
