import styled from 'styled-components';

export default styled.button`
  background-color: ${({ active, theme }) => (active ? theme.COLOR_GRAY_LIGHTER : theme.COLOR_BRAND_PRIMARY)};
  color: ${({ active, theme }) => (active ? theme.COLOR_BRAND_PRIMARY : theme.COLOR_WHITE)};
  border: 0;
  cursor: pointer;
  flex: 1;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  outline: 0;

  &:hover,
  &:focus {
    background-color: ${({ active, theme }) => (active ? theme.COLOR_GRAY_LIGHTER : theme.COLOR_BRAND_PRIMARY_DARK)};
    color: ${({ active, theme }) => (active ? theme.COLOR_BRAND_PRIMARY : theme.COLOR_WHITE)};
  }

  @media only screen and (min-width: ${({ theme }) => theme.DESKTOP}px) {
    padding: 1rem 2rem;
  }
`;
