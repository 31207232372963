import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { TabsNav, TabsLink } from '.';

class Tabs extends Component {
  static defaultProps = {
    children: null,
    tabs: null,
  };

  static propTypes = {
    children: PropTypes.node,
    tabs: PropTypes.arrayOf(PropTypes.shape()),
  };

  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
    };
  }

  render() {
    const Tab = this.props.tabs[this.state.tab].component;

    return (
      <Fragment>
        {this.props.children &&
          this.props.children
        }

        {!this.props.children &&
          <Fragment>
            <TabsNav>
              <Fragment>
                {this.props.tabs.map((tab, index) => (
                  <TabsLink
                    active={this.state.tab === index}
                    key={tab.label}
                    label={tab.label}
                    onClick={() => this.setState({ tab: index })}
                  />
                ))}
              </Fragment>
            </TabsNav>
            <Tab />
          </Fragment>
        }
      </Fragment>
    );
  }
}

export default Tabs;
