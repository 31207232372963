import styled from 'styled-components';
import { Modal } from '../ui';
import { below } from '../../styles/libs';
import { ModalContent } from '../ui/Modal';

export const WarmModal = styled(Modal)`
  ${({ theme }) => `
    background: ${theme.COLOR_WARM_BACKGROUND};
  `}
`;

export const BasketModalContent = styled(ModalContent)`
  ${({ theme }) => below(theme.TABLET)`
    padding: 2rem 1rem;
  `}
`;

export const ModalClose = styled.div`
  position: absolute;
  display: flex;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.25s;
  transform: scale(0.8);

  ${({ theme }) => `
    background: ${theme.COLOR_CYAN_DARK};
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const ComponentTable = styled.table`
  margin: 0 auto;
  text-align: left;

  ${({ theme }) => below(theme.TABLET)`
    width: 100%;
  `}
`;

export const ComponentTableRow = styled.tr`
  vertical-align: top;

  &:last-of-type {
    td {
      padding-bottom: 1rem;
    }
  }
`;

export const ComponentTableData = styled.td`
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;

  ${({ theme }) => `
    background: ${theme.COLOR_WHITE};
  `}
`;

export const ComponentTableHeader = styled.th`
  padding: 0.5rem;
`;

export const ModalBodyContent = styled.div`
  margin: 0 auto 1rem;
  text-align: center;

  ${({ theme }) => below(theme.TABLET)`
    max-width: 75%;
  `}
`;

export const ModalTitle = styled.h3`
  margin: 1rem auto 0.5rem;
  text-align: center;
  ${({ theme }) => `
    color: ${theme.COLOR_CYAN_DARK};
  `}
`;
