import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose, withApollo } from 'react-apollo';
import Router, { withRouter } from 'next/router';

import BasketHoldInventoryDialog from './BasketHoldInventoryDialog';
import BasketHoldInventoryBanner from './BasketHoldInventoryBanner';
import RESET_SESSION from '../Quote/graphql/resetSession';
import EXTEND_BASKET_HOLD_EXPIRY from '../Quote/graphql/extendBasketHoldExpiry';
import routes from '../../constants/routes';
import FetchPolicy from '../../constants/FetchPolicy';
import IbePropTypes from '../../IbePropTypes';

import GET_QUOTE from '../Quote/graphql/getQuote';

const minutesInMs = 60 * 1000;
const sessionExtensionMinutes = 1;
const minutesRemainingBeforeMaxToShowCheckOutNow = 1;

const dialogTypes = {
  none: undefined,
  inviteExtensionDialog: 'inviteExtensionDialog',
  checkOutNowDialog: 'checkOutNowDialog',
  maxTimeoutDialog: 'maxTimeoutDialog',
};

const BasketHoldInventoryManager = ({
  extendBasketHoldExpiry,
  quoteId,
  resetSession,
  router,
  client,
  onClosePopup,
}) => {
  const inviteExtensionTimeoutId = useRef();
  const maxTimeoutId = useRef();
  const checkOutNowTimeoutId = useRef();
  const [dialogType, setDialogType] = useState(dialogTypes.none);
  const [expiryInfo, setExpiryInfo] = useState({
    maxTimestamp: null,
    expiryTimestamp: null,
  });

  // Converts a string date to a unix time
  // e.g. ms since 01/01/1970
  const toEpoch = (dateString) => {
    if (!dateString) return null;
    return new Date(dateString).getTime();
  };

  // Helper for getting the most recent quote from the API
  // which gives us the correct expiry times
  const updateExpiryInfo = async () => {
    const response = await client.query({
      query: GET_QUOTE,
      fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });
    const quote = response?.data?.quote;
    if (!quote) return;
    const expiryTimestamp = toEpoch(quote.basketHoldExpiryTimestamp);
    const maxTimestamp = toEpoch(quote.basketHoldMaxTimestamp);
    setExpiryInfo({
      expiryTimestamp,
      maxTimestamp,
    });
  };

  useEffect(() => {
    // Get quote and set expiry timings in state
    updateExpiryInfo();
  }, [quoteId]);

  const destroyAllTimeouts = () => {
    if (inviteExtensionTimeoutId.current) {
      clearTimeout(inviteExtensionTimeoutId.current);
    }

    if (maxTimeoutId.current) {
      clearTimeout(maxTimeoutId.current);
    }

    if (checkOutNowTimeoutId.current) {
      clearTimeout(checkOutNowTimeoutId.current);
    }
  };

  const onClose = () => {
    setDialogType(dialogTypes.none);
  };

  const onResetQuote = async () => {
    await resetSession();
    if (router.route === routes.checkout) {
      Router.push(routes.sites);
    }
    setDialogType(dialogTypes.none);
    onClosePopup();
    client.writeData({
      data: {
        basketPopUp: {
          __typename: 'BasketPopUp',
          open: false,
          name: '',
        },
      },
    });
  };

  const onExtendTimeout = async () => {
    // catch to handle that BE can pass a 502 error
    // if there is a TL error, this stops the UI from breaking
    // and the popup continuing to close more detail on IBEP2-699
    await extendBasketHoldExpiry()
      .catch(error => console.error(error))
      .finally(() => setDialogType(dialogTypes.none));
    // Get most recent expiry info from the quote
    await updateExpiryInfo();
  };

  const onCheckOutNow = () => {
    setDialogType(dialogTypes.none);
  };

  useEffect(() => {
    destroyAllTimeouts();

    const onShowInviteExtension = () => {
      setDialogType(dialogTypes.inviteExtensionDialog);
    };

    const onShowMaxTimeout = () => {
      setDialogType(dialogTypes.maxTimeoutDialog);
    };

    const onShowCheckOutNow = () => {
      setDialogType(dialogTypes.checkOutNowDialog);
    };

    const now = new Date().getTime();

    // explicit global for QA
    // eslint-disable-next-line
    window.__CAMC_BASKET_HOLD_INVENTORY_MANAGER = {
      onShowInviteExtension,
      onShowTimeout: onShowMaxTimeout,
      onShowCheckOutNow,
    };

    if (expiryInfo.expiryTimestamp && expiryInfo.maxTimestamp) {
      const maxTimeoutMsFromNow = expiryInfo.maxTimestamp - now;
      const currentTimeoutMsFromNow
        = Math.min(maxTimeoutMsFromNow, expiryInfo.expiryTimestamp - now);

      const inviteExtensionMsFromNow
        = currentTimeoutMsFromNow - (minutesInMs * sessionExtensionMinutes);

      const checkOutNowMsFromNow
        = maxTimeoutMsFromNow - (minutesRemainingBeforeMaxToShowCheckOutNow * minutesInMs);

      if (
        inviteExtensionMsFromNow > 0
        && inviteExtensionMsFromNow < checkOutNowMsFromNow
      ) {
        // left in for now for QA to see dates
        // eslint-disable-next-line
        window.__CAMC_BASKET_HOLD_INVENTORY_MANAGER.showInviteExtension
          = new Date(now + inviteExtensionMsFromNow).toUTCString();
        inviteExtensionTimeoutId.current
          = setTimeout(onShowInviteExtension, inviteExtensionMsFromNow);
      }

      if (currentTimeoutMsFromNow > 0) {
        // left in for now for QA to see dates
        // eslint-disable-next-line
        window.__CAMC_BASKET_HOLD_INVENTORY_MANAGER.showTimeout
          = new Date(now + currentTimeoutMsFromNow).toUTCString();
        maxTimeoutId.current = setTimeout(onShowMaxTimeout, currentTimeoutMsFromNow);
      }

      if (checkOutNowMsFromNow > 0 && checkOutNowMsFromNow < currentTimeoutMsFromNow) {
        // left in for now for QA to see dates
        // eslint-disable-next-line
        window.__CAMC_BASKET_HOLD_INVENTORY_MANAGER.showCheckOutNow
          = new Date(now + checkOutNowMsFromNow).toUTCString();
        checkOutNowTimeoutId.current = setTimeout(onShowCheckOutNow, checkOutNowMsFromNow);
      }

      if (expiryInfo.expiryTimestamp - now < 0) {
        onResetQuote();
      }
    }

    return destroyAllTimeouts;
  }, [expiryInfo.expiryTimestamp, expiryInfo.maxTimestamp]);

  return (
    <>
      {(expiryInfo.expiryTimestamp && expiryInfo.maxTimestamp && quoteId) &&
        <>
          <BasketHoldInventoryBanner
            timeRemaining={expiryInfo.expiryTimestamp}
          />
          <BasketHoldInventoryDialog
            dictionaryRootName="DialogInviteExtension"
            open={dialogType === dialogTypes.inviteExtensionDialog}
            onClose={onClose}
            onConfirm={onExtendTimeout}
            quoteId={quoteId}
            timeRemaining={expiryInfo.expiryTimestamp}
          />
          <BasketHoldInventoryDialog
            dictionaryRootName="DialogCheckOutNow"
            open={dialogType === dialogTypes.checkOutNowDialog}
            onClose={onClose}
            onConfirm={onCheckOutNow}
            quoteId={quoteId}
            timeRemaining={expiryInfo.maxTimestamp}
          />
          <BasketHoldInventoryDialog
            dictionaryRootName="DialogMaxTimeout"
            open={dialogType === dialogTypes.maxTimeoutDialog}
            onClose={onResetQuote}
            onConfirm={onResetQuote}
            quoteId={quoteId}
          />
        </>
      }
    </>
  );
};

BasketHoldInventoryManager.propTypes = {
  extendBasketHoldExpiry: PropTypes.func.isRequired,
  quoteId: PropTypes.string,
  resetSession: PropTypes.func.isRequired,
  router: PropTypes.shape(IbePropTypes.router).isRequired,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  onClosePopup: PropTypes.func,
};

BasketHoldInventoryManager.defaultProps = {
  quoteId: undefined,
  onClosePopup: () => {},
};

export default compose(
  withRouter,
  withApollo,
  graphql(RESET_SESSION, {
    props: ({ mutate }) => ({
      resetSession: () => mutate({
        refetchQueries: ['Quote'],
      }),
    }),
  }),
  graphql(EXTEND_BASKET_HOLD_EXPIRY, {
    props: ({ mutate }) => ({
      extendBasketHoldExpiry: () => mutate({
        refetchQueries: ['Quote'],
      }),
    }),
  }),
)(BasketHoldInventoryManager);
