import React, { memo } from 'react';
import PropTypes from 'prop-types';

import StyledMessage from './Message.style';

const propTypes = {
  align: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  color: PropTypes.string,
  dictionary: PropTypes.string,
  marginBottom: PropTypes.bool,
  marginTop: PropTypes.bool,
};

const defaultProps = {
  align: 'center',
  backgroundColor: '',
  children: null,
  className: '',
  color: '',
  dictionary: '',
  marginBottom: false,
  marginTop: false,
};

function Message({
  align,
  backgroundColor,
  children,
  className,
  color,
  dictionary,
  marginBottom,
  marginTop,
  ...props
}) {
  return (
    <StyledMessage
      align={align}
      backgroundColor={backgroundColor}
      className={className}
      color={color}
      dictionary={dictionary}
      marginBottom={marginBottom}
      marginTop={marginTop}
      {...props}
    >
      {children}
    </StyledMessage>
  );
}

Message.propTypes = propTypes;
Message.defaultProps = defaultProps;

export default memo(Message);
