import React from 'react';
import PropTypes from 'prop-types';

import StyledLoadingBlock from './LoadingBlock.style';

const propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  marginLeft: PropTypes.string,
  marginTop: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.shape(),
  width: PropTypes.string,
};

const defaultProps = {
  backgroundColor: '',
  className: '',
  marginLeft: '0',
  marginTop: '0',
  size: '1rem',
  style: {},
  width: '100%',
};

function LoadingBlock(props) {
  return (
    <StyledLoadingBlock
      backgroundColor={props.backgroundColor}
      className={props.className}
      marginLeft={props.marginLeft}
      marginTop={props.marginTop}
      size={props.size}
      width={props.width}
      style={props.style}
    />
  );
}

LoadingBlock.propTypes = propTypes;
LoadingBlock.defaultProps = defaultProps;

export default LoadingBlock;
