import gql from 'graphql-tag';

// must pass an empty object (input: {}) here for POST rest-lint to not error with no body
// https://github.com/apollographql/apollo-link-rest/issues/147

export default gql`
mutation ExtendBasketHoldExpiry {
  extendBasketHoldExpiry(input: {}) @rest(
      type: "Quote"
      path: "Reservations/ExtendBasketHoldExpiry"
      method: "POST"
    ) {
      errorCode
      warnings
    }
}
`;
