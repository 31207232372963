import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  BasketBreakdownContainer,
  SectionRow,
  SectionText,
  LabelWithIcon,
  BasketSummaryIcon,
  BasketSummaryIconWrapper,
  BasketDivider,
} from './BasketSummary.style';
import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';
import { getComponentBreakdown } from '../../lib/helpers/amend';
import formatPrice from '../../lib/formatPrice';
import theme from '../../styles/config/theme';

import svgInfo from '../../static/images/icons/Info.svg';

import BasketModal from './BasketModal';

const MODALS = {
  RETAINED: 'retained',
  TOTAL: 'total',
};

const BasketBreakdown = ({ quote, client, isCheckout }) => {
  const [openModals, setOpenModals] = useState({
    [MODALS.RETAINED]: false,
    [MODALS.TOTAL]: false,
  });

  const { campsiteTotalBreakdown, cancellationTotal: cancellationBreakdown } =
    getComponentBreakdown(quote);

  const toggleModal = (modalToToggle) => {
    setOpenModals((current) => ({
      ...current,
      [modalToToggle]: !current[modalToToggle],
    }));
  };

  const campsitesPrice = campsiteTotalBreakdown.reduce((acc, current) => acc + current.price, 0);
  const cancellationTotal = cancellationBreakdown.reduce((acc, current) => acc + current.price, 0);

  return (
    <BasketBreakdownContainer isCheckout={isCheckout}>
      {isCheckout && <BasketDivider isCheckout={isCheckout} />}
      <SectionRow>
        <LabelWithIcon>
          <SectionText
            dictionary={dictionaryItem('BasketSummary', 'AllStayTotal')}
          />
          :
          <BasketSummaryIconWrapper onClick={() => toggleModal(MODALS.TOTAL)}>
            <BasketSummaryIcon
              icon={svgInfo}
              color={theme.COLOR_LAKE_GREEN_ORIGINAL}
            />
          </BasketSummaryIconWrapper>
        </LabelWithIcon>
        <SectionText>{formatPrice(campsitesPrice)}</SectionText>
        <BasketModal
          active={openModals[MODALS.TOTAL]}
          label={getDictionaryItem(client, 'BasketSummary__AllStayTotal__Text')}
          tableData={campsiteTotalBreakdown}
          closeModal={() => toggleModal(MODALS.TOTAL)}
        >
          {getDictionaryItem(client, 'BasketSummary__AllStayTotalModal__Text')}
        </BasketModal>
      </SectionRow>
      {cancellationTotal > 0 && (
        <SectionRow>
          <LabelWithIcon>
            <SectionText
              dictionary={dictionaryItem('BasketSummary', 'RetainedDeposit')}
            />
            :
            <BasketSummaryIconWrapper
              onClick={() => toggleModal(MODALS.RETAINED)}
            >
              <BasketSummaryIcon
                icon={svgInfo}
                color={theme.COLOR_LAKE_GREEN_ORIGINAL}
              />
            </BasketSummaryIconWrapper>
          </LabelWithIcon>
          <SectionText>{formatPrice(cancellationTotal)}</SectionText>
          <BasketModal
            active={openModals[MODALS.RETAINED]}
            label={getDictionaryItem(
              client,
              'BasketSummary__RetainedDeposit__Text',
            )}
            tableData={cancellationBreakdown}
            closeModal={() => toggleModal(MODALS.RETAINED)}
          >
            {getDictionaryItem(
              client,
              'BasketSummary__RetainedDepositModal__Text',
            )}
          </BasketModal>
        </SectionRow>
      )}
      {!isCheckout && <BasketDivider isCheckout={isCheckout} />}
    </BasketBreakdownContainer>
  );
};

BasketBreakdown.propTypes = {
  quote: PropTypes.shape(IbePropTypes.quote).isRequired,
  client: IbePropTypes.client.isRequired,
  isCheckout: PropTypes.bool,
};

BasketBreakdown.defaultProps = {
  isCheckout: false,
};

export default BasketBreakdown;
