import styled, { css } from 'styled-components';

import { above, below } from '../../styles/libs';
import detectIE from '../../lib/detectIE';

import Text from '../ui/Text';
import ButtonBrandStyled from '../ui/Button/ButtonBrand.style';
import ButtonBorderedStyled from '../ui/Button/ButtonBordered.style';
import { Icon } from '../ui';

const FULL_PADDING = '3rem';

export default styled.div`
  border-top: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  display: flex;
  flex-direction: column;
  ${({ isBookingBasket, theme }) => isBookingBasket &&
    css`
      background-color: ${theme.COLOR_GRAY_LIGHTER};
    `}
  ${({ full, theme }) => full && above(theme.TABLET)`
    flex-direction: row;
    align-items: center;
  `}
  ${!!detectIE() &&
  css`
    display: block;
  `}
`;

export const Details = styled.div`
  flex: 1;
`;

export const Section = styled.section`
  padding: 1rem 1.75rem;
  ${({ theme }) => below(theme.DESKTOP)`
    padding: 1rem;
  `}
  & + & {
    border-top: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  }
`;

export const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & + & {
    margin-top: 0.5rem;
  }
  ${({ noLabel }) => noLabel &&
    css`
      justify-content: flex-end;
    `}
`;

export const SectionRowNav = styled(SectionRow)`
  display: flex;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  ${({ theme }) => below(theme.DESKTOP)`
    flex-direction: column;
  `}
`;

export const SectionText = styled(Text)`
  ${({ bold }) => `
    font-weight: ${bold ? 'bold' : 'normal'};
    font-size: ${bold ? '1rem' : '0.875rem'};
  `}

  ${({ theme, bold }) => above(theme.TABLET)`
    font-size: ${bold ? '1.25rem' : '1rem'};
  `}
`;

export const TextMember = styled(SectionText)`
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
`;

export const PriceTotal = styled(Text)`
  font-size: 1rem;
  line-height: 1;
  ${({ theme }) => above(theme.TABLET)`
    font-size: 1.375rem;
  `}
  ${({ oldPrice }) => oldPrice &&
    css`
      text-decoration: line-through;
    `}
  ${({ theme, newPrice }) => newPrice &&
    css`
      color: ${theme.COLOR_AMBER_ORANGE};
    `}
`;

export const CTA = styled.div`
  flex: 1;
  padding: 0 1.75rem 1rem;
  ${({ full }) => full &&
    css`
      padding-left: ${FULL_PADDING};
    `}
  ${({ theme }) => above(theme.TABLET)`
    padding: 0 1.75rem 1.75rem;
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex: 1;

  ${({ isFullBasket }) => css`
    padding: ${isFullBasket ? '1rem' : '0 1.75rem 1.75rem'};
  `}

  ${({ theme }) => below(theme.TABLET)`
    padding: 0 1.75rem 1rem;
    flex-direction: column-reverse;
    gap: 0.5rem;
    padding-top: 0;
  `}
`;

// Be careful of increasing  size here as it can cause checkout
// to wrap, causing no content display on screen.
export const AmendmentDiscardButton = styled(ButtonBorderedStyled)`
  width: 100%;
  padding: 0;
  font-size: 0.875rem;
`;

export const ButtonCheckout = styled(ButtonBrandStyled)`
  font-size: 1rem;
  padding: 1rem 2rem;
  width: 100%;
`;

const bookingBasketButtons = css`
  flex-grow: 1;
  padding: 0.25rem 0;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  ${({ theme }) => below(theme.DESKTOP)`
    padding: 0.75rem 0;
  `}
`;

export const ButtonPayBalance = styled(ButtonBrandStyled)`
  ${bookingBasketButtons}
  ${({ theme }) => below(theme.DESKTOP)`
    width: 100%;
    order: 2;
  `}
  ${({ flex }) => flex &&
    css`
      flex: ${flex};
    `}
`;

export const ButtonAdd = styled(ButtonBorderedStyled)`
  ${bookingBasketButtons}
  ${({ theme }) => below(theme.DESKTOP)`
    width: 100%;
    order: 1;
  `}

  ${({ flex }) => flex &&
    css`
      flex: ${flex};
    `}
`;

export const LabelWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const BasketSummaryIcon = styled(Icon)`
  margin-left: 0.25rem;
  font-size: 1.25rem;
  cursor: pointer;
`;

export const BasketSummaryIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BasketDivider = styled.div`
  ${({ theme, isCheckout }) => `
    border-top: 1px solid ${theme.COLOR_GRAY_LIGHT};
    margin: ${isCheckout ? '0 0 1rem' : '0.5rem'};
  `}
`;

export const BasketBreakdownContainer = styled.div`
  ${({ isCheckout }) => isCheckout && `
    padding: 0rem 2rem 1rem;
  `}
`;
