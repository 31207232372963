import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import Modal, { ModalClose } from '..';
import { ModalContentStyled } from '../FormModal/FormModal.style';
import Title from '../../Title';
import Text from '../../Text';
import {
  IconHeader, Action, ActionBrand, Actions,
} from './NoticeModal.style';
import LoadingSpinner from '../../Loading/LoadingSpinner';

import svgError from '../../../../static/images/icons/Error.svg';
import { Col, Row } from '../../Grid';

const NoticeModal = ({
  children,
  descriptionDictionary,
  descriptionText,
  icon,
  onClose,
  onPrimaryAction,
  onSecondaryAction,
  open,
  primaryActionDictionary,
  secondaryActionDictionary,
  titleDictionary,
  titleText,
  theme,
  isLoading,
  iconColor,
  titleColor,
  textColor,
  actionsDirection,
  actionsReversePlacement,
  onClickOutside,
}) => (
  <Modal active={open} overlay onClickOutside={onClickOutside}>
    <ModalContentStyled center>
      {onClose && !isLoading && <ModalClose type="Button" onClick={onClose} />}
      {!isLoading && (
        <IconHeader
          icon={icon || svgError}
          color={iconColor || theme.COLOR_LAKE_GREEN_ORIGINAL}
        />
      )}
      {isLoading && <LoadingSpinner marginBottom />}
      <Title
        align="center"
        dictionary={titleDictionary}
        tag={3}
        size={2}
        marginBottom="0.5rem"
        color={titleColor}
      >
        {titleText}
      </Title>
      {(descriptionDictionary || descriptionText) && (
        <Text
          align="center"
          size="1rem"
          dictionary={descriptionDictionary}
          color={textColor}
        >
          {descriptionText}
        </Text>
      )}
      {children}
      {(!!onPrimaryAction || !!onSecondaryAction) && !isLoading && (
        <Actions>
          <Row flexDirection={actionsDirection || 'column'}>
            {onPrimaryAction && primaryActionDictionary && !actionsReversePlacement && (
              <Col>
                <ActionBrand
                  onClick={onPrimaryAction}
                  dictionary={primaryActionDictionary}
                />
              </Col>
            )}
            {onSecondaryAction && secondaryActionDictionary && (
              <Col>
                <Action
                  onClick={onSecondaryAction}
                  dictionary={secondaryActionDictionary}
                />
              </Col>
            )}
            {onPrimaryAction && primaryActionDictionary && actionsReversePlacement && (
              <Col>
                <ActionBrand
                  onClick={onPrimaryAction}
                  dictionary={primaryActionDictionary}
                />
              </Col>
            )}
          </Row>
        </Actions>
      )}
    </ModalContentStyled>
  </Modal>
);

NoticeModal.propTypes = {
  children: PropTypes.node,
  descriptionDictionary: PropTypes.string,
  descriptionText: PropTypes.string,
  icon: PropTypes.string,
  onClose: PropTypes.func,
  onPrimaryAction: PropTypes.func,
  onSecondaryAction: PropTypes.func,
  open: PropTypes.bool,
  primaryActionDictionary: PropTypes.string,
  secondaryActionDictionary: PropTypes.string,
  theme: PropTypes.shape({
    COLOR_LAKE_GREEN_ORIGINAL: PropTypes.string,
    COLOR_TEXT_BODY: PropTypes.string,
  }),
  titleDictionary: PropTypes.string,
  titleText: PropTypes.string,
  isLoading: PropTypes.bool,
  iconColor: PropTypes.string,
  titleColor: PropTypes.string,
  textColor: PropTypes.string,
  actionsDirection: PropTypes.string,
  actionsReversePlacement: PropTypes.bool,
  onClickOutside: PropTypes.func,
};

NoticeModal.defaultProps = {
  children: undefined,
  descriptionDictionary: '',
  descriptionText: '',
  icon: '',
  onClose: undefined,
  onPrimaryAction: undefined,
  onSecondaryAction: undefined,
  open: false,
  primaryActionDictionary: '',
  secondaryActionDictionary: '',
  theme: {},
  titleDictionary: '',
  titleText: '',
  isLoading: false,
  iconColor: '',
  titleColor: '',
  textColor: '',
  actionsDirection: '',
  actionsReversePlacement: false,
  onClickOutside: undefined,
};

export default withTheme(NoticeModal);
