import styled, { css } from 'styled-components';

import Message from './Message';

export default styled(Message)`
  font-size: 0.875rem;
  text-align: left;
  background-color: ${({ theme, bgColor }) => bgColor || theme.COLOR_DANGER};
  color: ${({ theme }) => theme.COLOR_WHITE};

  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: 1rem;
  `}

  ${({ fullWidth }) => fullWidth && css`
    display: block;
    width: 100%;
  `}
`;
