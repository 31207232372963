import PropTypes from 'prop-types';

export const standardPropTypes = {
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
};

export const standardDefaultProps = {
  error: false,
  loading: false,
};
