import React from 'react';
import PropTypes from 'prop-types';

import StyledTabsNav from './TabsNav.style';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function TabsNav({ children }) {
  return (
    <StyledTabsNav>
      {children}
    </StyledTabsNav>
  );
}

TabsNav.propTypes = propTypes;

export default TabsNav;
