export const AMEND_RESTRICTIONS = {
  UNRESTRICTED: 0,
  STARTING_SOON: 1,
  HAS_CROSSING: 2,
  PITCH_RESTRICTIONS: 3,
  GUEST_STAY: 4,
  ON_REQUEST_STAY: 5,
  DISABLED: 6,
  CONTAINS_MEMBERSHIP: 7,
  CONTAINS_PREVENT_AMEND_OR_CANCEL_EXTRA: 8,
  CONTAINS_PREVENT_REMOVE_EXTRA: 9,
  PITCH_STARTING_SOON: 10,
  OSNV_RESTRICTION: 11,
};

export const AMEND_KEYS = {
  [AMEND_RESTRICTIONS.STARTING_SOON]: 'StartingSoon',
  [AMEND_RESTRICTIONS.HAS_CROSSING]: 'HasCrossing',
  [AMEND_RESTRICTIONS.PITCH_RESTRICTIONS]: 'PitchRestrictions',
  [AMEND_RESTRICTIONS.GUEST_STAY]: 'GuestStay',
  [AMEND_RESTRICTIONS.ON_REQUEST_STAY]: 'OnRequestStay',
  [AMEND_RESTRICTIONS.DISABLED]: 'Disabled',
  [AMEND_RESTRICTIONS.CONTAINS_MEMBERSHIP]: 'ContainsMembership',
  [AMEND_RESTRICTIONS.CONTAINS_PREVENT_AMEND_OR_CANCEL_EXTRA]: 'ContainsPreventBookingAmendOrCancelExtra',
  [AMEND_RESTRICTIONS.CONTAINS_PREVENT_REMOVE_EXTRA]: 'ContainsPreventRemovingFromBookingExtra',
  [AMEND_RESTRICTIONS.PITCH_STARTING_SOON]: 'PitchStartingSoon',
  [AMEND_RESTRICTIONS.OSNV_RESTRICTION]: 'OsnvAmendmentRestrictedMinimumHoursBeforeStartDate',
};

export const getAmendRestrictionReason = (
  bookingRestrictions = [], pitchRestrictions, wholeBooking, osnv = false,
) => {
  const allRestrictions = [...bookingRestrictions];
  const bookingRestriction = allRestrictions.shift();
  if (!bookingRestriction ||
    (bookingRestriction === AMEND_RESTRICTIONS.OSNV_RESTRICTION && !osnv) ||
    (bookingRestriction === AMEND_RESTRICTIONS.CONTAINS_PREVENT_REMOVE_EXTRA && !wholeBooking)) {
    return allRestrictions[0] || AMEND_RESTRICTIONS.UNRESTRICTED;
  }
  if (bookingRestriction === AMEND_RESTRICTIONS.PITCH_RESTRICTIONS) {
    if (wholeBooking) {
      return AMEND_RESTRICTIONS.PITCH_RESTRICTIONS;
    }
    return pitchRestrictions?.[0] || allRestrictions[0] || AMEND_RESTRICTIONS.UNRESTRICTED;
  }
  if (bookingRestriction === AMEND_RESTRICTIONS.CONTAINS_MEMBERSHIP && pitchRestrictions) {
    return pitchRestrictions[0];
  }
  return bookingRestriction;
};
