export default function CleanQuery(query) {
  const routerQuery = { ...query };
  // Remove "__typename"
  delete routerQuery.__typename; // eslint-disable-line no-underscore-dangle

  if (routerQuery.types) {
    // Remove features or types if empty
    if (routerQuery.types.length === 0) delete routerQuery.types;
  }

  if (routerQuery.features && routerQuery.features.length === 0) delete routerQuery.features;
  return routerQuery;
}
