import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';

import Text from '../ui/Text';
import { getDictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';
import BasketHoldInventoryCountDown from './BasketHoldInventoryCountDown';

export const Container = styled.div`
  padding: 0.5rem 1.75rem;
  background: #e9e9e9;
`; // bespoke colour from design

export const Label = styled(Text)`
  font-size: 0.6875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.COLOR_TEXT_HEADING};
`;

const BasketHoldInventoryBanner = ({ client, timeRemaining }) => {
  const embedString = useRef(getDictionaryItem(client, 'BasketHoldInventoryBanner__Text'));

  return (
    <Container>
      <Label>
        <BasketHoldInventoryCountDown
          timeRemaining={timeRemaining}
          embedString={embedString.current}
        />
      </Label>
    </Container>
  );
};

BasketHoldInventoryBanner.propTypes = {
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  timeRemaining: PropTypes.number,
};

BasketHoldInventoryBanner.defaultProps = {
  timeRemaining: undefined,
};

export default withApollo(BasketHoldInventoryBanner);
