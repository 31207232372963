import styled from 'styled-components';
import Text from '../Text';

export default styled(Text)`
  padding: 0.375rem 0.625rem;
  border-radius: 4px;
  text-align: ${({ align }) => align};
  font-size: 0.8125rem;
  background-color: ${({ backgroundColor }) => backgroundColor || 'inherit'};
  color: ${({ color }) => color || 'inherit'};
`;
