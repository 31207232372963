import {
  format,
  differenceInHours,
} from 'date-fns';

/**
 * Convert given date to UTC
 * @param date Plain JS date object or Date() acceptable string
 * @return {(Date | null)} Returns a UTC date for valid inputs, otherwise null
 */
export function toUTC(dateParam) {
  let date = dateParam;
  if (typeof dateParam === 'string') {
    date = new Date(dateParam);
  }

  if (date instanceof Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }
  return null;
}

export function formatUTC(date, pattern) {
  return format(+(toUTC(new Date(date))), pattern);
}

export function differenceInDays(toDate, fromDate) {
  const startUTC = toUTC(fromDate);
  const endUTC = toUTC(toDate);
  const hours = differenceInHours(endUTC, startUTC);
  return Math.floor(hours / 24);
}
