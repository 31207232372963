import gql from 'graphql-tag';

export default gql`
  query PopUp {
    becomeAMemberPopUp @client
    bookingOverlapDialog @client
    voucherDialog @client
    siteNightVoucherPopUp @client {
      open
      minimum
    }
    depositComponentSummaryPopUp @client {
      open
      components {
        deposit
        name
      }
    },
    loginPopUp @client {
      open
      title
      prompt
      redirectTo
      showMembership
    }
    cancelSiteBookingPopUp @client {
      open
      refund
      title
    }
    basketPopUp @client {
      open
      name
    }
    upSellPopUp @client {
      open
      saving
    }
    removePackagePopUp @client {
      open
      componentId
    }
    removeVouchersPopUp @client {
      open
      componentId
    }
    richTextPopUp @client {
      open
      text
    }
    membershipExpiredPopUp @client {
      open
      isOverseas
      showProceed
    }
    noticePopUp @client {
      open
      type
    }
    formPopUp @client {
      open
      type
    }
  }
`;
