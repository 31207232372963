import React from 'react';
import PropTypes from 'prop-types';

import StyledTabsLink from './TabsLink.style';

const propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function TabsLink(props) {
  return (
    <StyledTabsLink
      active={props.active}
      onClick={props.onClick}
    >
      {props.label}
    </StyledTabsLink>
  );
}

TabsLink.propTypes = propTypes;

export default TabsLink;
