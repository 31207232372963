import styled, { css, keyframes } from 'styled-components';

import { multiply } from '../../../styles/libs/calculate';

const gradient = keyframes`
  0% {
    background-position: -50vw 0;
  }
  50% {
    background-position: 0vw 0;
  }
  100% {
    background-position: 50vw 0;
  }
`;

export default styled.div`
  display: inline-block;
  background-clip: border-box;

  ${({
    backgroundColor,
    size,
    width,
    theme,
    marginLeft,
    marginTop,
  }) => css`
    position: relative;
    overflow: hidden;
    width: ${width};
    margin-left: ${marginLeft};
    margin-top: ${marginTop};
    /* Mimic line-height */
    height: ${multiply(size, 1.25)};
    padding: ${multiply(size, 0.25)} 0;

    &::after {
      content: "";
      left: 0;
      top: 0;
      position: absolute;
      height: 100%;
      animation: ${gradient} 1500ms linear infinite;
      background: ${backgroundColor || `linear-gradient(90deg, ${theme.COLOR_GRAY_LIGHTER}, ${theme.COLOR_GRAY_LIGHT}, ${theme.COLOR_GRAY_LIGHTER})`};
      width: 100vw;
    }
  `}
`;
