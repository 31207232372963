// Constants file used in:
//  -- IBE (ES6 Imports)
//  -- Playwright Tests (CommonJS imports)
//
// This uses module.exports to be backwards-compatible

const VEHICLE_TYPES = {
  CAR: 0,
  MOTORHOME: 1,
  ROOF_TENT: 2,
  CAMPERVAN: 3,
  VAN: 4,
  NO_CAR: 5,
  CAR_ROOF_TENT: 6,
  VAN_ROOF_TENT: 7,
  OTHER: 8,
  NONE: 9,
};

const TOW_TYPES = {
  NONE: 0,
  CARAVAN: 1,
  TRAILER: 2,
  TRAILER_TENT: 3,
  OTHER: 4,
  CAR: 5,
  TENT: 6,
};

const CROSSINGS_VEHICLE_TYPES = [
  VEHICLE_TYPES.CAR,
  VEHICLE_TYPES.MOTORHOME,
];

const CROSSINGS_TOW_TYPES = [
  TOW_TYPES.NONE,
  TOW_TYPES.CARAVAN,
];

const TOURING_REQUIRED_TOW = [
  VEHICLE_TYPES.CAR,
  VEHICLE_TYPES.VAN,
];

const OVERSEAS_VEHICLE_TYPES = [
  VEHICLE_TYPES.CAR,
  VEHICLE_TYPES.MOTORHOME,
];

const OVERSEAS_TOW_TYPES = [
  TOW_TYPES.NONE,
  TOW_TYPES.CARAVAN,
];

const OVERSEAS_CAR_TOW_TYPES = [
  TOW_TYPES.CARAVAN,
];

module.exports = {
  VEHICLE_TYPES,
  TOW_TYPES,
  CROSSINGS_VEHICLE_TYPES,
  CROSSINGS_TOW_TYPES,
  TOURING_REQUIRED_TOW,
  OVERSEAS_VEHICLE_TYPES,
  OVERSEAS_TOW_TYPES,
  OVERSEAS_CAR_TOW_TYPES,
};
