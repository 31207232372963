import styled from 'styled-components';
import { ButtonBordered } from '../../Button';
import { buttonBrandCSS } from '../../Button/ButtonBrand.style';
import Icon from '../../Icon';

export const IconHeader = styled(Icon)`
  font-size: 3.5rem;
  color: ${({ theme, color }) => `${color || theme.COLOR_LAKE_GREEN_DARK}`};
  margin: 1rem auto;
`;

export const Actions = styled.div`
  width: 100%;
`;

export const Action = styled(ButtonBordered)`
  margin-top: 1rem;
  flex: 1;
  width: 100%;
`;

export const ActionBrand = styled(Action)`
  ${buttonBrandCSS}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
`;
