import { util } from 'node-forge';
import { isLoggedInMember } from '../isLoggedIn';
import quoteMembershipTypes, { getENumWithValue } from '../../config/quoteMembershipTypes';

export function generatePayload({
  id, inboundItinerary, outboundItinerary, outfit, partyMembers,
}, user, result) {
  const membershipByDD = getENumWithValue(quoteMembershipTypes.MembershipByDD);
  const membershipLoggedIn =
    getENumWithValue(quoteMembershipTypes.LoggedInMember);
  const userIsMember = isLoggedInMember(user);

  const formatPayload = {
    crossingBookings: [
      {
        id,
        ...(Object.keys(inboundItinerary).length > 0 ? {
          inboundItinerary: {
            ...inboundItinerary,
            crossingDateTime: result?.inboundItinerary?.crossingDateTime,
          },
        } : {}),
        ...(Object.keys(outboundItinerary).length > 0 ? {
          outboundItinerary: {
            ...outboundItinerary,
            crossingDateTime: result?.outboundItinerary?.crossingDateTime,
          },
        } : {}),
      },
    ],
    membershipType: userIsMember ? membershipLoggedIn : membershipByDD,
    outfit,
    partyMembers: partyMembers.map((partyMember) => ({
      ...partyMember,
      age: null,
    })),
    errataAccepted: true,
  };

  return formatPayload;
}

function formatExtras(arr) {
  return arr.map(({ id, quantity }) => ({ id, quantity }));
}

export function formatSearch({
  id,
  outfit,
  partyMembers,
  inboundItinerary,
  outboundItinerary,
}) {
  const accommodation = formatExtras(
    outboundItinerary.accommodation.concat(inboundItinerary.accommodation || []),
  );

  const supplements = formatExtras(
    outboundItinerary.supplements.concat(inboundItinerary.supplements || []),
  );

  const payload = {
    outfit,
    partyMembers,
    departureDate: outboundItinerary?.crossingDateTime,
    selectedItinerary: {
      id,
      accommodation,
      supplements,
    },
  };

  return payload;
}

export function parseQuery({ search }) {
  const decoded = search ? util.decode64(search) : '{}';
  return search ? JSON.parse(decoded) : {};
}

export function returnPortName(ports = [], providedPortCode) {
  if (!providedPortCode) return '';

  const matchingPort = ports
    .find(port => port.portCodes
      .find(portCode => portCode === providedPortCode)) || {};

  return matchingPort.name || '';
}

export function returnSupplierName(suppliers = [], providedPortCode) {
  if (!providedPortCode) return '';

  const matchingPort = suppliers
    .find(supplier => supplier.supplierCodes
      .find(supplierCode => supplierCode === providedPortCode)) || {};

  return matchingPort.name || '';
}

export function returnSupplierLogo(suppliers = [], providedPortCode) {
  if (!providedPortCode) return '';

  const matchingPort = suppliers
    .find(supplier => supplier.supplierCodes
      .find(supplierCode => supplierCode === providedPortCode)) || {};

  return matchingPort.logoURL || '';
}

export function fetchAllMatchingRoutes(itinerary, routes) {
  if (!routes.length) return [];

  const allRoutes = routes
    .filter(
      route => route.departurePortName === itinerary.departurePortName &&
      route.arrivalPortName === itinerary.arrivalPortName,
    );

  return [...allRoutes];
}

export function formatPortNameByCode(ports, portCode) {
  const port = ports.find(({ portCodes }) => portCodes.includes(portCode));
  return port ? port.name : portCode;
}
