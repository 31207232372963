import Router from 'next/router';
import getConfig from 'next/config';
import { identity, pickBy } from 'lodash';
import { stringify } from 'querystring';

import cleanQuery from './cleanQuery';
import { parseTypes } from './campsiteTypes';

export function parseQuery(queryToParse = {}, queriesToExclude = {}) {
  const query = {
    ...Router.query,
    ...queryToParse,
  };

  // Convert query props to numbers
  const numberProps = [
    'zoomLevel',
  ];

  const floatProps = [
    'ne_lat',
    'ne_lng',
    'sw_lat',
    'sw_lng',
  ];

  numberProps.forEach((prop) => {
    if (!query[prop]) return;
    query[prop] = Number(query[prop]);
  });

  floatProps.forEach((prop) => {
    if (!query[prop]) return;
    query[prop] = parseFloat(query[prop]);
  });

  // initially types is undefined updateRouterQuery is called by map update.
  query.types = parseTypes(query.types);

  if (queriesToExclude?.length) {
    queriesToExclude.forEach((key) => {
      query[key] = undefined;
    });
  }

  return query;
}

export function stringifyQuery(query = {}, exclude = []) {
  return `?${stringify(pickBy(cleanQuery(parseQuery(query, exclude)), identity))}` || '';
}

export const redirectFromWidget = (route, query) => {
  const { publicRuntimeConfig } = getConfig();
  const IBEUrl = publicRuntimeConfig?.ENVIRONMENT?.IBEUrl;
  const queryString = stringifyQuery(query, ['bookingWidget']);
  window.parent.location.href = `${IBEUrl}${route}${queryString}`;
};

/**
 * Fundamental application function used to update router, all query string changes should
 * come through this function
 *
 * @param {string} path
 * @param {string} query
 * @param {string} method
 * @param {boolean} scroll
 * @returns confirmation that router query needed change and additional valuable url data
 */
export default async function updateRouterQuery(path = '/', query = '', method = 'push', scroll = false) {
  const queryString = query && `${stringifyQuery(query)}`;
  const href = `${path}${queryString}`;
  const as = href;

  if (href === Router.router.asPath) {
    return {
      hasChanged: false,
      queryString,
      href,
    };
  }

  await Router[method](href, as, { scroll });

  return {
    hasChanged: true,
    queryString,
    href,
  };
}

export const addOrUpdateQueryParam = async (name, value) => {
  if (typeof window === 'undefined') return false;
  if (value === undefined) return false;
  // Stringify value so we can enter falsy values to the querystring (e.g. 0)
  await updateRouterQuery(window.location.pathname, {
    [name]: value.toString(),
  });
  return true;
};
