export function stringifyPartyMembers(party, partyMemberTypes, partyMemberReference, isAmend) {
  const partyObj = {};
  let partyCopy = [...party];
  if (!isAmend && partyMemberReference?.length) {
    partyCopy = partyCopy.filter(({ personId }) => partyMemberReference.includes(personId));
  }

  partyCopy.forEach(({ type }) => {
    const partyMemberType = partyMemberTypes.find(({ key }) => key === type) || {};
    const partyMemberTypeValue = partyMemberType.value;

    partyObj[partyMemberTypeValue] = partyObj[partyMemberTypeValue] ?
      partyObj[partyMemberTypeValue] + 1 :
      1;
  });

  // eg. 'Adult x 1, Child x 2, Infant x 1'
  if (!Object.keys(partyObj).length) return 'None';
  return Object.keys(partyObj).map(key => `${key} x ${partyObj[key]}`).join(', ');
}

export function groupGuests(guests) {
  return guests.reduce((grouped, guest) => {
    const groupedCopy = { ...grouped };
    const key = `${guest.stayStart}-${guest.stayEnd}`;
    if (groupedCopy[key]) {
      groupedCopy[key].push(guest);
    } else {
      groupedCopy[key] = [guest];
    }
    return groupedCopy;
  }, {});
}

export default { stringifyPartyMembers };
