import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import DOMPurify from 'isomorphic-dompurify';

import { getDictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';

const embedStringReplace = '{{time}}';

const BasketHoldInventoryCountDown = ({ client, embedString, timeRemaining }) => {
  const [remaining, setRemaining] = useState('');
  const remainingAboveOneMinute = useRef(getDictionaryItem(client, 'BasketHoldInventoryCountDown__AboveOneMinute__Text'));
  const remainingLessThanOneMinute = useRef(getDictionaryItem(client, 'BasketHoldInventoryCountDown__LessThanOneMinute__Text'));
  const remainingZeroSeconds = useRef(getDictionaryItem(client, 'BasketHoldInventoryCountDown__ZeroSeconds__Text'));
  const updateRemainingIntervalId = useRef();

  const destroyInterval = () => {
    if (updateRemainingIntervalId.current) {
      clearInterval(updateRemainingIntervalId.current);
    }
  };

  useEffect(() => {
    destroyInterval();
    const secondsInMs = (1000);
    const minutesInMs = (60 * secondsInMs);

    const updateRemaining = () => {
      const now = new Date().getTime();
      if (timeRemaining && now < timeRemaining) {
        // difference in minutes from timeRemaining to now
        const difference = timeRemaining - now;
        let differenceString = remainingAboveOneMinute.current
          .replace('{{class}}', 'highlight')
          .replace('{{minutes}}', Math.floor(difference / minutesInMs));
        if (difference < minutesInMs) {
          differenceString = remainingLessThanOneMinute.current.replace('{{class}}', 'warn');
        }

        setRemaining(differenceString);
      } else {
        setRemaining(remainingZeroSeconds.current.replace('{{class}}', 'error'));
      }
    };

    updateRemaining();
    updateRemainingIntervalId.current = setInterval(updateRemaining, minutesInMs);

    return destroyInterval;
  }, [timeRemaining]);

  return (
    <span dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(embedString?.replace(embedStringReplace, remaining)),
    }}
    />
  );
};

BasketHoldInventoryCountDown.propTypes = {
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  timeRemaining: PropTypes.number,
  embedString: PropTypes.string,
};

BasketHoldInventoryCountDown.defaultProps = {
  timeRemaining: undefined,
  embedString: embedStringReplace,
};

export default withApollo(BasketHoldInventoryCountDown);
