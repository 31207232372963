export const partyMemberStayTypes = {
  ADVANCED_GUEST: 2,
};

export const personTypes = {
  ADULT: 0,
  CHILDREN: 1,
  INFANT: 2,
};

export const mapPartyTypes = {
  0: 'Adults',
  1: 'Children',
  2: 'Infants',
};

export const mapPartyType = {
  0: 'Adult',
  1: 'Child',
  2: 'Infant',
};

export const partyConfig = [
  {
    defaultValue: 2,
    fromAge: 18,
    min: 1,
    toAge: null,
    type: 0,
  },
  {
    fromAge: 3,
    toAge: 17,
    type: 1,
  },
  {
    fromAge: 0,
    toAge: 3,
    type: 2,
  },
];
