import React from 'react';
import PropTypes from 'prop-types';

import StyledLoadingBlockButton from './LoadingBlockButton.style';

const defaultProps = {
  backgroundColor: '',
  block: false,
  maxWidth: null,
  size: 'medium',
  style: {},
};

const propTypes = {
  backgroundColor: PropTypes.string,
  block: PropTypes.bool,
  maxWidth: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.shape(),
};

function LoadingBlockButton(props) {
  const buttonProps = {
    block: props.block,
    backgroundColor: props.backgroundColor,
    disabled: true,
    maxWidth: props.maxWidth,
    size: props.size,
    style: props.style,
  };

  return (
    <StyledLoadingBlockButton {...buttonProps} />
  );
}

LoadingBlockButton.defaultProps = defaultProps;
LoadingBlockButton.propTypes = propTypes;

export default LoadingBlockButton;
